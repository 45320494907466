@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');


h1{
    font-family: 'Archivo Black', sans-serif !important;

}
h2{

    font-family: 'Archivo Black', sans-serif !important;
font-size: 28px;
color:#293586;
margin: 10px 0px;


}
li{
  list-style-type: disc;
    font-size: 16px;
    color:#293586; 
 
    
}

h3{
    font-family: 'Archivo Black', sans-serif !important;
     color:white;
     margin-bottom: 10px;

}
.consultancy{

background-image: url('../../../assets/consult_BG.png');
}
.cloud-solutions{
    background-image: url('../../../assets/cloud_SoluBG.png');

}

.it-infrastucture{
    background-image: url('../../../assets/it_infraBG.png');

}
