
.lil{
    list-style-type: none;
}
*{
     
    font-size: 16px;
}
/* .dropdown-menu{
    position: relative;
     padding:  100px;
} */

    

 