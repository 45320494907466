 
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,400;0,500;0,600;1,300&display=swap');
*{

   
}
  .chatbtn-container{
  z-index: 1000;
  font-family: 'Archivo', sans-serif;
  background-color:hsla(334, 83%, 54%, 1);
  width: 50px;
  padding: 15px;
  height: 50px; 
  color: white;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
   border-radius: 50%;
  box-shadow: 1px 1px 15px #aaaaaa;
  margin: 30px 60px;
  right: 0;
  }
  .wa-container{
  z-index: 1000;
  font-family: 'Archivo', sans-serif;
  background-color:hsla(334, 83%, 54%, 1);
  width: 50px;
  padding: 15px;
  height: 50px; 
  color: white;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
   border-radius: 50%;
  box-shadow: 1px 1px 15px #aaaaaa;
  margin: 100px 60px;
  right: 0;
  }
  p{
    cursor: pointer;
  }
  
  .chatbtn-container:hover{
    color: #832780;
  font-size: 2.5rem;
  background-color: white;
  }
  .wa-container:hover{
    color: #10fb10;
  font-size: 2.5rem;
  background-color: white;
  }
  
  .chatbot-view{
    transition: all 3s ease;
    height:75vh;
    width: 40vw;
    max-width: 350px;
    position: fixed;
    min-width: 350px;
    right:0;
   bottom: 15%;
border-top: 1px solid #293586;

   margin: 10px 20px;
   background-color: white;
   border-radius: 20px;
   }
  
  .bot-header{
  z-index: 50000;
  height: 15%;
 
  width: 100%;
  border-left: 1px solid #293586;
border-right: 1px solid #293586;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
 
  padding:  40px 15px;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  color: #832780;
  
   
  
  }
  
  i:active{
  
    background-color: rgb(124, 124, 124);
    border-radius: 10%;
    padding: 5px;
  }
  
  .main-chat{
    
 
    height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
   
  
  
  }
  
  .type-msg{
    font-size: 12px;
   flex: 1;
   background-color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
   padding: 13px 15px;
   border-bottom-left-radius: 20px;
   border-left: 1px solid #293586;
border-right: 1px solid #293586;
border-bottom: 1px solid #293586;
   border-bottom-right-radius: 20px;
  
  
  }
  .msgs{
    padding: 5px;
     flex: 8;
    background-color: #F6F8FF;
    
   width: 100%;
  }
  
  #input{
  flex: 1;
 
   outline:none;
margin: 0px 5px;
  border-radius: 10px;
  font-size:.7rem;
 padding:10px 8px;
  border: 1px solid #293586;
  }

   


  .msgs{
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: scroll;
  
 
    border-left: 1px solid #293586;
    border-right: 1px solid #293586;
    width: 100%;
    
  }
  @keyframes typing {
    from {
      height: 0;
    }
    to {
      height: 100%;
    }
  }
  #responss{
    display: flex;
    flex-direction: column;

    }
    #responss p {
        font-size:.9rem;
    }
  .botm{
    max-width: 80%;
    background-color: #686afb;
    border-radius: 20px;
    padding: 10px;
    color:white;
      margin-bottom: 10px;
    
      transition: all 1s ease;
    margin-top: 10px;
  p{
    pointer:cusor;
     
    font-weight: bold;
  }
  a{
    display: block;
text-decoration: underline;  }
  }
  .userm{
    max-width: 80%;
    justify-self: end;
    transition: all 1s ease;
    align-self: end;
    background-color: #8a8a8a;
    border-radius: 20px;
     padding: 10px;
    color:white;
   
  }

  