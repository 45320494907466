@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

h2{

    font-family: 'Archivo Black', sans-serif !important;
font-size: 28px;
color:#293586;
margin: 10px 0px;

}
.p{
  
    font-size: 16px;
    color:#293586; 
 
    
}

h3{
    font-family: 'Archivo Black', sans-serif !important;
     color:white;
     margin-bottom: 10px;

}
.img{ 
 }