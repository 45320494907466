.hireus{
font-size: 16px;
padding:8px 20px;
background-color: #EB267A;
font-weight: bold;
border-radius: 10px;
color: white;
}

.hireus:active{
    transform: scale(.5);
}