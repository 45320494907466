@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');


.header{

    background-repeat: no-repeat;
    background-image: url('../../../assets/gradient-hue.png');
    background-size:cover; 
    padding: 40px 20px;
    font-family: 'Archivo Black', sans-serif !important;

}
.h2{
    margin-top: 10px;
color: #293586;
font-family: 'Archivo Black', sans-serif;
font-size: 22px;
    
}


label{
    display: flex;
    justify-content: start;
    align-items: start;
     color:#293586 ;
}
input{
border-radius: 10px;
    background-color:#F6F8FF;
    outline: none;
}

