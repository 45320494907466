/* @import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,400;0,500;0,600;1,300&display=swap');
@tailwind base;
@tailwind components;

@tailwind utilities;

*{
  border: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
   }
body {
  margin: 0;
  padding:0;
  overflow-x: hidden;
  font-family: 'Archivo', sans-serif;}
.hero{
  background: no-repeat url("./assets/homeBG.png");
  background-size: cover;
  
}
.services{
  background: no-repeat center url("./assets/serviceBG.png");
  width: 100% !important;
  background-size: cover;
}


.image:hover{  transform: scale(1.1);

}

img:hover{  transform: scale(1.1);

}
 
.hover :hover{

  background-color: #293586 !important;
}


.primary-btn{
  @apply bg-primary text-white text-xs py-3 px-5 rounded-md
}
.res-cont{   
  @apply w-full md:px-28 px-8 py-10
} 

.small-heading{
  @apply font-bold text-lg text-primary
}
.heading{
  @apply font-black text-customBlue md:text-3xl px-0 md:pr-20 text-xl my-2
}
#blog-frame{


   height: 100vh;
}





        /* Menu Styles STarts Here */
        .nav-area .servicesMenu {
          position: absolute;
          padding: 10px 0;
          display: none;
          transition: 0.7s;
          margin-top: 40px;
        }
        .nav-area div:hover > .servicesMenu {
          display: flex;
        }
        .nav-area .servicesMenu  ul li {
          position: relative;
        }
        .nav-area  .servicesMenu  ul {
          top: 0;
          left: 100%;
        }
        /* Menu Styles Ends Here */